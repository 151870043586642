<template>
  <div>
    <!-- Back Button -->
    <v-row style="margin-bottom: 20px">
      <router-link style="margin-right: 20px" :to="{ name: 'StatisticsCrawlers' }">zurück</router-link>
      <v-icon size="40px">$pacman</v-icon>
      <div style="font-size: 30px; margin-left: 20px">Crawlers</div>
    </v-row>

    <!-- Data -->
    <!-- Table -->
    <v-data-table
      :headers="headers"
      :items="data"
      class="unvergessen-shadow"
      :items-per-page="-1"
      @click:row="handleClick"
    >
      <template v-slot:item.paper="{ item }">
        <div style="width: 100%; height: 100%; cursor: pointer; padding-top: 15px" @click.stop="pushToPaper(item.baseURL)">
          {{item.paper}}
        </div>
      </template>
      <template v-slot:item.idDistribution="{ item }">
        <div style="width: 100%; height: 100%; cursor: pointer; padding-top: 15px">
          <span v-for="(element, i, index) in item.idDistribution" :key="index">
            {{ i === 'false' ? 'ohne' : i }}: {{Math.round(element * 100)}}%;
          </span>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      data: [],
      headers: [
        { text: 'ID', value: 'singleId' },
        { text: 'Zeitung', value: 'paper' },
        { text: 'Region', value: 'location' },
        { text: 'Durchläufe', value: 'runs' },
        { text: 'Gefunden', value: 'casesInTotal' },
        { text: 'Ausgeschlossen', value: 'preFiltered' },
        { text: 'Duplikate', value: 'duplicatesPrefiltered' },
        { text: 'Veröffentlicht', value: 'casesPushed' },
        { text: 'Ausstehend', value: 'inQueue' },
        { text: 'IDs heute', value: 'idDistribution' }
      ]
    }
  },
  methods: {
    handleClick (value) {
      this.$router.push({ name: 'StatisticsCrawlersInfo', query: { id: value._id } })
    },
    pushToPaper (url) {
      window.open(url, '_blank')
    }
  },
  created () {
    axios.get('/statistics/crawler/allCrawlerDailyStats')
      .then(res => {
        this.data = res.data.details
      })
      .catch(() => {})
  }
}
</script>
